import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import data from '../content/data.json';

const pageStyles = {
	padding: "2rem"
}

const NotFoundPage = () => {
	return (
		<main style={pageStyles}>
			<title>{data.general.site_name} | 404</title>
			<Container>
				<Row>
					<Col>
						<h1>404</h1>
						<p>Page not found.</p>
						<a href="/" aria-label="Go to homepage">&rarr; Home</a>
					</Col>
				</Row>
			</Container>
		</main>
	)
}

export default NotFoundPage
